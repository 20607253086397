import * as React from 'react';

export const ContactDetails: React.FC = () => {
  return (
    <p className="contact-details">
      For spørsmål - Send en henvendelse til{' '}
      <b>
        <a href="mailto:support@broker.no">support@broker.no</a>
      </b>
    </p>
  );
};
