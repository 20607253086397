import { createGlobalStyle } from 'styled-components';

interface Theme {
  eiendomsprofil: { [key: string]: any };
  root: { [key: string]: any };
}

declare module 'styled-components' {
  export interface DefaultTheme extends Partial<Theme> {}
}

export const GlobalStyles = createGlobalStyle`
    /*
      Remove logo in Cookiebot dialog
    */
    #CybotCookiebotDialogPoweredbyCybot {
      display: none !important;
    }



    * {
      box-sizing: border-box;
    }

    html {
      -webkit-text-size-adjust: 100%;
      font-size: 16px;
    }

    html, body, #root {
      width:100%;
      height: 100%;
    }
    
    body {
      padding: 0;
      margin: 0;
      background: var(--body-background-color, #fff);
      color: var(--body-color, #000);
      font-family: "UbuntuRegular",Tahoma,Arial,Helvetica,Verdana,sans-serif;
      line-height: 1.5;

      &.no-scroll {
        position: fixed;
        overflow-y: hidden;
      }

      &.correction {
        .map-wrapper {
          pointer-events: none;
        }
      }
    }

    h1, h2, h3, h4, h5, h6 {
      font-family: "UbuntuBold",Tahoma,Arial,Helvetica,Verdana,sans-serif;
      line-height: 1;
      margin-top: 0;
      margin-bottom: 0.5em;
    }

    p {
      margin-top: 0;
      margin-bottom: 1em;
      user-select: text;
    }

    a {
      text-decoration: none;
      color: #fff;
      word-break: break-word;
    }
    

    button {
      appearance: none;
      border: none;
      &:hover {
        cursor: pointer;
      }
      svg {
        pointer-events: none;
      }
    }

    button, a, input, textarea {
        font-family: inherit;
    }

    input, textarea {
      border-radius: 0;
    }

    img {
      vertical-align: middle;
    }    

    .print-visible {
      display: none !important;
    }

    @media print {
      body {
        margin: 0;


      }
      
      h1, h2, h3, h4, h5, h6, a, p {
        page-break-inside: avoid;
      }

      .print-hidden {
        display: none !important;
        width: 0 !important;
        height: 0 !important;
        overflow: hidden !important;
        line-height: 0 !important;
        white-space: nowrap;
      }
      
      .print-visible {
        display: block !important;
      }
    }

    .fade-in {
      animation: fadeIn 0.15s ease-in-out forwards;
    }

    @keyframes fadeIn {
      0% {
        opacity: 0;
      }
      100% {
        opacity: 1;
      }
    }

   
`;
